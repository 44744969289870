
/**
 * @name: 抖音团购-核销记录
 * @author: lili
 * @date: 2023-09-19 17:53
 * @description： 抖音团购-核销记录
 * @update: 2023-09-19 19:53
 */
import {Vue, Component} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {deepCopy, exportFile} from "@/utils/common";
import {writeOffRecordExportApi, writeOffRecordQueryApi} from "@/apis/tiktok";

@Component({})
export default class tiktokList extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: any = {
    page: 1,
    limit: 10
  }
  // 配货数量
  distributeStock: string = '0.00'
  // 配货金额
  distributionAmount: string = '0.00'
  // 表单参数
  modelForm: any = {}
  gateList: any = []
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "关键字",
        prop: "keywords",
        align: 'center',
        search: true,
        placeholder: "券码、团购ID/订单编号/名称",
        hide: true,
        width:'500px'
      },
      {
        label: "券码ID",
        prop: "originCode",
        align: 'center',
      },
      {
        label: "关联销售订单编号",
        prop: "saleOrderSn",
        align: 'center',
      },
      {
        label: "关联团购ID",
        prop: "groupPurchaseId",
        align: 'center',
      },
      {
        label: "关联团购名称",
        prop: "groupPurchaseName",
        align: 'center',
      },
      {
        label: "金额",
        prop: "groupPurchasePrice",
        align: 'center',
      },
      {
        label: "核销人",
        prop: "verifiedName",
        align: 'center',
        search: true,
      },

      {
        label: "核销时间",
        prop: "addTime",
        align: "center",
        type: "daterange",
        width: 180,
        //@ts-ignore
        default: '--',
        // @ts-ignore
        search: true,

      },
    ]
  }

  getList() {
    const form: any = deepCopy(this.queryParam);
    if (this.queryParam.addTime && this.queryParam.addTime.length) {
      form.addTimeStart = this.queryParam.addTime[0]
      form.addTimeEnd = this.queryParam.addTime[1]
    } else {
      form.addTimeStart = ""
      form.addTimeEnd = ""
    }
    delete form.addTime
    writeOffRecordQueryApi(form).then(e => {
      //@ts-ignore
      this.tableData = e.list;
      this.tableTotal = e.total
    })
  }

  /**
   * 导出excel
   */
  exportExcel() {
    const form: any = deepCopy(this.queryParam);
    if (this.queryParam.addTime && this.queryParam.addTime.length) {
      form.addTimeStart = this.queryParam.addTime[0]
      form.addTimeEnd = this.queryParam.addTime[1]
    } else {
      form.addTimeStart = ""
      form.addTimeEnd = ""
    }
    delete form.addTime
    writeOffRecordExportApi(form).then(e => {
      exportFile(e, '核销记录记录.xlsx')
    })
  }


  created() {
    // this.getGateList()
    this.getList()
  }
}
